const works = [{
  imgUrl: 'fever',
  artist: 'RMR',
  title: 'FEVER',
  credits: 'drums',
  link: 'https://open.spotify.com/track/0PmvAsXoTMs0ugnC7PGnSM',
}, {
  imgUrl: 'goodnights',
  artist: 'Fitz and the Tantrums',
  title: 'Good Nights',
  credits: 'drums',
  link: 'https://open.spotify.com/track/1mDH62NHaDVBM2yymWaSDj',
}, {
  imgUrl: 'nextyear',
  artist: 'Macklemore',
  title: 'Next Year',
  credits: 'drums',
  link: 'https://open.spotify.com/track/4zncVqi72AX54I2JVIUq0N',
}, {
  imgUrl: 'bestday',
  artist: 'Kesha',
  title: 'Best Day',
  credits: 'drums, percussion, 808, hype',
  link: 'https://open.spotify.com/track/2eh4qe6wmq7he6O76CS0o3',
}, {
  imgUrl: 'sleepwalkers',
  artist: 'Life Vest',
  title: 'Sleepwalkers',
  credits: 'drums, lap steel, bass, production',
  link: 'https://open.spotify.com/album/3cvjz0b03vgL10hJr6Sfci',
}, {
  imgUrl: 'gravity',
  artist: 'Moped Genius',
  title: 'Gravity EP',
  credits: 'drums, production',
  link: 'https://open.spotify.com/album/6B8zMfcL77yN61DqduZSNJ',
}, {
  imgUrl: 'closedmyeyes',
  artist: 'Automotive Steamhorse',
  title: 'I Closed My Eyes and Nothing Happened LP',
  credits: 'drums, samples',
  link: 'https://open.spotify.com/album/5kXRqVjA8IcqWiWD36Bv4C',
}, {
  imgUrl: 'heartbeats',
  artist: 'Automotive Steamhorse',
  title: 'Heartbeats Float Like Jets LP',
  credits: 'drums, samples',
  link: 'https://open.spotify.com/album/5KhAhQ03qut66yashuAZ1r',
}, {
  imgUrl: 'warvoyeur',
  artist: 'Stucky Jackson and the Boys',
  title: 'War Voyeur EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/6W1qYEepLgJZT8mB7mtJbD',
}, {
  imgUrl: 'lamentations',
  artist: 'Stucky Jackson and the Boys',
  title: 'Lamentations from the Grave LP',
  credits: 'guitar',
  link: 'https://open.spotify.com/album/78aa3wcOn0Sos89N4aEF9D',
}, {
  imgUrl: 'kaleidoscopes',
  artist: 'Chris Benis',
  title: 'Kaleidoscopes',
  credits: 'drums',
  link: 'https://open.spotify.com/album/3tTB02RWoCdldlcWVk1lFZ',
}, {
  imgUrl: 'anotherday',
  artist: 'Stucky Jackson and the Boys',
  title: 'Another Day',
  credits: 'drums',
  link: 'https://open.spotify.com/album/6KyQthYAbWcAyUsA1NrlAd',
}, {
  imgUrl: 'beautifullife',
  artist: 'Stucky Jackson and the Boys',
  title: 'Beautiful Life',
  credits: 'drums',
  link: 'https://open.spotify.com/album/7bmTZTQaKafmLy9mLCdtY2',
}, {
  imgUrl: 'thenotion',
  artist: 'Chris Benis',
  title: 'The Notion EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/5J64LLrzJWsmzPafI20i4Q',
}, {
  imgUrl: 'blizzard',
  artist: 'All Known Aliases',
  title: 'Blizzard EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/63rSW1bNKmPBbvEPzNWSqc',
}, {
  imgUrl: 'gotagoodvibe',
  artist: 'Coleman Jennings',
  title: 'Got a Good Vibe EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/52ZtH6AROyowJnbH0dVYtz',
}, {
  imgUrl: 'getyoualone',
  artist: 'Golden Idols',
  title: 'Get You Alone',
  credits: 'drums, bg vocals, songwriting',
  link: 'https://open.spotify.com/album/0v1ZCREtxAlrQYEzrxslTd',
}, {
  imgUrl: 'uneasy',
  artist: 'Golden Idols',
  title: 'Uneasy EP',
  credits: 'drums, bg vocals, songwriting',
  link: 'https://open.spotify.com/album/6tXirYSdLfqlzWFX6yLNly',
}, {
  imgUrl: 'highschoolprom',
  artist: 'Golden Idols',
  title: 'High School Prom',
  credits: 'drums',
  link: 'https://goldenidols.bandcamp.com/track/high-school-prom',
}, {
  imgUrl: 'holysmokes',
  artist: 'Golden Idols',
  title: 'Holy Smokes! LP',
  credits: 'drums, bg vocals, guitar, songwriting',
  link: 'https://open.spotify.com/album/7JU0pezSK2SWPqzcR3LwIY',
}, {
  imgUrl: 'keptmewaiting',
  artist: 'Golden Idols',
  title: 'Kept Me Waiting EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/1TM4Ks0GcZzlZCL4kGg7DE',
}, {
  imgUrl: 'special',
  artist: 'Golden Idols',
  title: 'Special',
  credits: 'drums, bg vocals',
  link: 'https://open.spotify.com/album/1B8HypdDgcMOfV7up3wHw2',
}, {
  imgUrl: 'messingaround',
  artist: 'Golden Idols',
  title: 'Messing Around EP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/7qXezfnqoT1JqDOG1iZq2u',
}, {
  imgUrl: 'mywholeyouth',
  artist: 'Golden Idols',
  title: 'My Whole Youth',
  credits: 'drums',
  link: 'https://open.spotify.com/album/1iX7s4XsQS4QRaCWNkipAB',
}, {
  imgUrl: 'levels',
  artist: 'Levels',
  title: 'V EP',
  credits: 'drums, songwriting',
  link: 'https://levelstheband.bandcamp.com/',
}, {
  imgUrl: 'sol',
  artist: 'Sol',
  title: 'Yours Truly LP',
  credits: 'drums',
  link: 'https://open.spotify.com/album/4gIx52vEdijhx5WINMy5Zw',
}, {
  imgUrl: 'dj',
  artist: 'Dyno Jamz',
  title: 'self-titled LP',
  credits: 'drums, songwriting, production',
  link: 'https://dynojamz.bandcamp.com/',
}];

export default works;
