import React from 'react';
import styled from 'styled-components';
import works from './works';

export default function App() {
  return (
    <Container>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <Header>
        <div>
          I played on these albums.<br />
          I will play on yours too.
        </div>
        <Socials>
          <Social href="https://www.instagram.com/sababababa/">
            Instagram
            {/* {' '}
            <span className="material-symbols-outlined">
              photo_camera
            </span> */}
          </Social>
          <Social href="mailto:saba.samakar@gmail.com">
            Email
            {/* {' '}
            <span className="material-symbols-outlined">
            mail
          </span> */}
          </Social>
          {/* <Social href="sms:+12065748827">
            Text me
          </Social> */}
        </Socials>
        <HowToClickLinks>
          Tap on a picture to visit Spotify or Bandcamp.
        </HowToClickLinks>
      </Header>
      <Works>
        {works.map((w, index) => (
          <Credit key={`credit-${index}`}>
            <a href={w.link} target="_blank" rel="noreferrer">
              <AlbumCover src={`${process.env.PUBLIC_URL}/album-covers/${w.imgUrl}.jpeg`} />
            </a>
            <Text>
              {`${w.artist} - ${w.title}`}
              <br />
              <MiniCred>
                {w.credits}
              </MiniCred>
            </Text>
          </Credit>
        ))}
      </Works>
      <Footer>
        <Social target="_blank" href="https://icons8.com/icon/68800/snare-drum-top" rel="noreferrer">Snare Drum Top</Social>
        {' '}
        icon by
        {' '}
        <Social target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</Social>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  background-color: black;
  display: flex
  color: #D9D9D9;
  font-family: 'Roboto';
`;

const Header = styled.div`
  color: #D8D8D8;
  font-size: 36px;
  margin: 12px;
`;

const Socials = styled.div`
  margin: 8px 0 28px 0;
  display: flex;
  flex-direction: column;
`;

const Social = styled.a`
  color: #D8D8D8;
  // text-decoration: none; /* no underline */
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
`;

const Works = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: #D8D8D8;
`;

const HowToClickLinks = styled.div`
  color: #D8D8D8;
  font-size: 10px;
  text-align: center;
  margin: 16px 0 8px 0;
  font-style: italic;
`;

const Credit = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 290px;
  font-size: 14px;
  margin-bottom: 12px;
`;

const AlbumCover = styled.img`
  max-width: 290px;
`;

const Text = styled.div`
  text-align: center;
  line-height: 20px;
  margin-top: 4px;
`;

const MiniCred = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-bottom: 12px;
`;

const Footer = styled.div`
  font-size: 6px;
  color: #D8D8D8;

`;
